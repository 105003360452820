import {AgGridReact} from 'ag-grid-react';
import {useState, useEffect, useMemo, useCallback, useRef} from 'react';
import { Helmet } from 'react-helmet';
import { Form, Card, Button, ProgressBar, ListGroup, Modal, Badge, Row, Col, Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../../css/multistep.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'





import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';


var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('/');
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    // minValidYear: 2000,
    // maxValidYear: 2021,
    inRangeFloatingFilterDateFormat: 'DD MMM YYYY',
  };

// function formatDate(dateString) {
//     const date = new Date(dateString);
//     return date.toLocaleDateString();
// }

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

function formatDateTime(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();
  
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}-${month}-${year}\n${hours}:${minutes}:${seconds}`;
}


function GridExample() {

    const gridRef = useRef();
    

    // const [rowData, setRowData] = useState([
    //     {make: 'Ford', model: 'Focus', price: 40000},
    //     {make: 'Toyota', model: 'Celica', price: 50000},
    //     {make: 'BMW', model: '4 Series', price: 60000}
    // ]);

    // to be used if removing the const defaultColDef
    // const [columnDefs, setColumnDefs] = useState([
    //     {field: 'make', sortable: true, filter: true},
    //     {field: 'model', sortable: true, filter: true},
    //     {field: 'price', sortable: true, filter: true}
    // ]);

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const [rowData, setRowData] = useState();

    const [columnDefs, setColumnDefs] = useState([
            {field: 'job_title', headerName: 'Job Title'},
            {field: 'skills', headerName: 'Skills'},
            {field: 'summary', headerName: 'Summary'},
            {field: 'industry', headerName: 'Industry'},
            {field: 'current_location', headerName: 'Current Location'},
            {field: 'experience', headerName: 'Experience (years)', cellStyle: { textAlign: 'center' }},
            {field: 'current_designation', headerName: 'Current Designation'},
            {field: 'ug_degree', headerName: 'UG Degree'},
            {field: 'ug_spl', headerName: 'UG Specialization'},
            {field: 'pg_degree', headerName: 'PG Degree'},
            {field: 'pg_spl', headerName: 'PG Specialization'},
            {field: 'cand_name', headerName: 'Name'},
            {field: 'func_area', headerName: 'Functional Area'},
            {field: 'current_company', headerName: 'Current Company'},
            {field: 'preferred_location', headerName: 'Preferred Location'},
            {field: 'annual_salary', headerName: 'Annual Salary (LPA)', cellStyle: { textAlign: 'center' }},
            {field: 'notice_period', headerName: 'Notice Period (days)', cellStyle: { textAlign: 'center' }},
            {field: 'dob', valueFormatter: params => formatDate(params.value), headerName: 'Date of Birth',filter: "agDateColumnFilter"},
            {field: 'age', headerName: 'Age', cellStyle: { textAlign: 'center' }},
            {field: 'marital_status', headerName: 'Marital Status'},
            {field: 'phone', headerName: 'Phone'},
            {field: 'email', headerName: 'Email'},
            {field: 'gender', headerName: 'Gender'},
            {field: 'work_permit', headerName: 'Work Permit'},
            {field: 'resume', headerName: 'Resume', cellRenderer: function(params){
              // console.log('PDF URL:', params.value);
              if (params.value !== null){
              const filename = params.value.split('\\').pop();
              return (
              <a href={`http://localhost:3002/${params.value}`} target="_blank">{filename}</a>
              );
              }
            }},
            {field: 'uploaded_at', valueFormatter: params => formatDateTime(params.value), headerName: 'Uploaded At'}
            // {field: 'willing_to_relocate'},
            // {field: 'resume_path'},
            // {field: 'age'},
            // {field: 'date', filter: 'agDateColumnFilter', filterParams: filterParams,}
        ]);

    const defaultColDef = useMemo( () => ({
        minWidth: 150,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: {},
        suppressFloatingFilterButton: true,
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true,
        },
    }), []);

    const cellClickedListener = useCallback ( e => {
        console.log('cellClicked', e);
    })  

    useEffect (() => {
        // fetch('http://143.110.190.50:3172/csv-data')
        // .then((resp) => resp.json())
        // .then((data) => setRowData(data));

        fetch('http://143.110.190.50:3172/csv-data')
        .then((resp) => {
            if (!resp.ok) {
                throw new Error('Network response was not ok');
            }
            return resp.json();
        })
        .then((data) => setRowData(data))
        .catch((error) => {
            // Handle error
            console.error('Error fetching data:', error);
            // You can set an error state or display an error message here
        });
    }, [])


    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setGridOption(
          'quickFilterText',
          document.getElementById('filter-text-box').value
        );
      }, []);

      const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
      }, []);

    // const pushMeClicked = useCallback( e => {
    //     gridRef.curent.api.deselectAll();
    // })

    return (
        <>
        <Navbar collapseOnSelect expand="lg" variant="dark" sticky="top" id="navbar2" className="nav" style={{height: '80px'}}>  
        <Container>  
          <Navbar.Brand href="#" style={{fontSize: 18, color: '#101e45'}}>
            <img src="img/logo_small.png" alt="brand-logo" height="40" width="34" style={{marginRight: '10px'}}/>
            TalentCo Resume Management
          </Navbar.Brand>  
          <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ borderStyle: 'none', height: '30px'}}> <FontAwesomeIcon icon={faEllipsisVertical} style={{color: '#02224d', fontSize: '20px'}}/><FontAwesomeIcon icon={faEllipsisVertical} style={{color: '#02224d', fontSize: '20px'}}/><FontAwesomeIcon icon={faEllipsisVertical} style={{color: '#02224d', fontSize: '20px'}}/> </Navbar.Toggle>  
          <Navbar.Collapse id="responsive-navbar-nav">  
            <Nav className="me-auto">  

              {/* <Nav.Link href="#features">Link 1</Nav.Link>  
              <Nav.Link href="#pricing">Link 2</Nav.Link>    */}
            </Nav>  
            <Nav activeKey="2" >
              {/* <NavLink eventKey="1" to="/" style={{margin: '15px', marginTop: '20px', color: '#101e45', textDecoration: 'none'}}>Resume Form</NavLink>   */}
              <NavLink className="nav-link" eventKey="2" to="/filter" style={{margin: '15px', color: '#d71728', textDecoration: 'none'}}>View Resumes</NavLink>  
              {/* <NavLink eventKey="3" to="/upload" style={{margin: '15px', marginTop: '20px', color: '#101e45', textDecoration: 'none'}}>Upload Resumes</NavLink> */}
              {/* <Nav.Link eventKey="3" href="http://localhost:3000/djangofilter" style={{margin: '5px'}}>Parsed Resumes</Nav.Link>  
              <Nav.Link eventKey="3" href="http://127.0.0.1:8000/" style={{margin: '5px'}}>Resume Parser</Nav.Link>  */}
            </Nav>  
          </Navbar.Collapse>  
        </Container>  
      </Navbar>
      {/* <div className="example-header" style={{marginTop: '10px'}}>
          <span>Keyword Filter:</span>
          <input
            type="text"
            id="filter-text-box"
            placeholder="Type here to search"
            onInput={onFilterTextBoxChanged}
            style={{marginLeft: '10px'}}
          />
        </div>   */}
        <div className='ag-theme-quartz' style={{height: "75vh" }}>
        <Helmet>
        <title>Talentco | View Resumes</title>
        </Helmet>
        <div style={{marginTop: '15px'}}>
        <span style={{fontStyle: "10px"}}>Keyword Search:</span>
          <input
            type="text"
            id="filter-text-box"
            placeholder="Search here..."
            // onInput={onFilterTextBoxChanged}
            style={{ marginLeft: '5px'}}
          />
          <button onClick={onFilterTextBoxChanged} style={{ marginLeft: '5px'}}>Search</button>
        <button className="me-auto" onClick={onBtnExport} style={{marginLeft: "30px", fontSize: "10px"}}>Download CSV export file</button>
            {/* <button onClick={pushMeClicked}>Push Me</button> */}

        </div>
            <AgGridReact
                ref={gridRef}
                onCellClicked={cellClickedListener}
                rowData={rowData}
                columnDefs={columnDefs}
                // rowSelection='multiple'
                animateRows={true}
                defaultColDef={defaultColDef}
                style={{fontSize: '12px'}}
                pagination={true}
                alwaysShowHorizontalScroll={true}
                alwaysShowVerticalScroll={true}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                rowBuffer={0}
                />
                
        </div>
        </>
    )
}

export default GridExample